

































































































































































import Vue from 'vue';
import {mapGetters, mapMutations, mapActions} from 'vuex'
// import debounce from 'lodash.debounce'

const timeText = function (t) {
  if (isNaN(t)) return '00:00';
  const m = Math.floor(t / 60);
  const s = t % 60;
  let mm: string, ss: string;
  mm = (m < 10) ? ('0' + m.toString()) : m.toString();
  ss = (s < 10) ? ('0' + s.toString()) : s.toString();
  return '' + mm + ':' + ss;
}

export default Vue.extend({
  name: 'GameMaster',
  props: {
  },
  data () {
    return {
      newSessionPin: '',
      newSession: {
        id: '',
        open: true,
        allowSelfStart: true,
        started: false
      },
      selectedSessionId: null,
      selectedTeamId: null,
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'uid',
      'scenarioId',
      'scenario',
      'sessions',
      'allTeams',
      'teamRankings'
    ]),
    userIsNotLoggedIn () {
      if (this.user) return this.user.isAnonymous;
      return true;
    },
    userIsActuallyLoggedIn () {
      return (this.user && this.user.uid && !this.user.isAnonymous)
    },
    selectedSession () {
      if (this.selectedSessionId) return this.sessions[this.selectedSessionId];
      return null
    },
    selectedSessionCompletedTraining () {
      let members = []
      for (let t in this.allTeams) {
        if (this.allTeams[t].completedBridge) {
          members = members.concat(this.allTeams[t].members)
        }
      }
      members.sort()
      return members
    },
    selectedTeam () {
      if (this.selectedSession && this.allTeams && this.selectedTeamId) return this.allTeams[this.selectedTeamId];
      return null
    },
    selectedTeamAquaTheaterTime () {
      if (this.selectedTeam) return timeText(this.selectedTeam.timeAquaTheater);
      return ''
    },
    selectedTeamBoardwalkTime () {
      if (this.selectedTeam) return timeText(this.selectedTeam.timeBoardwalk);
      return ''
    },
    selectedTeamCentralParkTime () {
      if (this.selectedTeam) return timeText(this.selectedTeam.timeCentralPark);
      return ''
    },
    selectedTeamPromenadeTime () {
      if (this.selectedTeam) return timeText(this.selectedTeam.timePromenade);
      return ''
    },
    selectedTeamTheaterTime () {
      if (this.selectedTeam) return timeText(this.selectedTeam.timeTheater);
      return ''
    },
    selectedTeamBridgeTime () {
      if (this.selectedTeam) return timeText(this.selectedTeam.timeBridge);
      return ''
    },
  },
  methods: {
    ...mapMutations([
      'sessionId',
    ]),
    ...mapActions([
      'subscribeToSessions',
      'createSession',
      'updateSession',
      'subscribeToTeams',
      'updateTeam'
    ]),
    signInThroughProvider (provider) {
      this.busy = true;
      this.$store.dispatch('signInThroughProvider', provider)
      .then(user => {
        // this.$router.push({name: 'Reroute'});
      })
      .catch(error => {
        alert(error.message);
      })
      .finally(() => {
        this.busy = this.authActionsInproc;
      });
    },

    createNewSession () {
      if (!this.newSessionPin) return false;
      this.newSession.id = this.scenarioId+'-'+this.newSessionPin;
      this.createSession(this.newSession)
    },
    selectSession (session) {
      this.selectTeam(null)
      if (session === null) {
        this.selectedSessionId = null
        return
      }
      this.selectedSessionId = session.id
      this.sessionId(session.id)
      this.subscribeToTeams()
    },
    saveSelectedSession () {
      this.updateSession(this.selectedSession)
    },
    startSession () {
      const update = {
        id: this.selectedSession.id
      }
      update['started'] = true
      this.updateSession(update)
    },

    selectTeam (team) {
      if (team === null) {
        this.selectedTeamId = null
        return
      }
      this.selectedTeamId = team.id
    },
    clearRequestForAssistance () {
      this.updateSelectedTeam({requiresAssistance: 0})
    },
    addHint () {
      this.updateSelectedTeam({hintsRemaining: this.selectedTeam.hintsRemaining+1})
    },
    subtractHint () {
      if (this.selectedTeam.hintsRemaining > 0) {
        this.updateSelectedTeam({hintsRemaining: this.selectedTeam.hintsRemaining-1})
      }
    },
    addPenalty () {
      this.updateSelectedTeam({timePenalty: this.selectedTeam.timePenalty+30})
    },
    subtractPenalty () {
      if (this.selectedTeam.timePenalty >= 30) {
        this.updateSelectedTeam({timePenalty: this.selectedTeam.timePenalty-30})
      }
    },
    updateTeamProp (prop) {
      const update = {
        id: this.selectedTeam.id
      }
      update[prop] = this.selectedTeam[prop]
      if (typeof update[prop] === 'boolean') update[prop] = update[prop] ? 1 : 0
      this.updateTeam(update)
    },
    updateSelectedTeam (update) {
      update.id = this.selectedTeam.id
      this.updateTeam(update)
    },

    teamStatus (team) {
      if (team.requiresAssistance) return '<i class="bi-flag-fill"> Requires Assistance';
      if (!(this.selectedSession && this.selectedSession.started)) {
        return team.ready ? 'READY!' : ''
      }
      if (team.completedBridge) return 'DONE!';
      let count = 0
      if (team.ready) {
        if (team.completedAquaTheater) count++
        if (team.completedBoardwalk)   count++
        if (team.completedCentralPark) count++
        if (team.completedPromenade)   count++
        if (team.completedTheater)     count++
      }
      return '' + count + ' of 6'
    },
  },
  mounted () {
    console.log('GameMaster.mounted', this.user)
    this.selectedSessionId = null
    this.selectedTeamId = null
    if (this.userIsActuallyLoggedIn) {
      this.subscribeToSessions()
    }
  },
  watch: {
    user (newVal, oldVal) {
      console.log('user changed from', oldVal, 'to', newVal)
      if (this.userIsActuallyLoggedIn) {
        this.subscribeToSessions()
      }
    }
  }
});
